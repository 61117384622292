import { Container } from "react-bootstrap";

export default function IntroductionBanner() {
  return (
    <>
      <Container>
        <p>I am a Senior Software Engineer working at Microsoft on the Network UX team in Windows. Network Settings, Network Icon, Wi-Fi Direct, and a website to allow mobile operators to make changes to COSA and Mobile Plans are all projects I have worked on. Prior to Microsoft, I worked at Alarm.com, where I helped onboard Qolsys Gen2 Panel into the company infrastruture, maintained and enhanced the critical alarm messaging processor, and expanded the capabilities of video on Android. Before that, I graduated from The Univsersity of Texas at Austin 🤘</p>

        <p>In my spare time, I enjoying playing video games and board games with my friends, lifting weights, running long distances, playing/walking with my dog, and enjoying family time. I occasionally dabble with open source software for fun and to learn 💻</p>

        <p>You can also check out my blog at blog.englishcrusade.net where I write about random stuff that might be interesting to you.</p>
      </Container>
    </>
  )
}